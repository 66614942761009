import { useEffect } from "react";
import Callpage from "./components/CallPage";
import CustomizedSnackbars from "./components/Snackbar";
import controls, { endCall } from "./components/controls";
import Loading from "./components/Loading";
import AudioPlayer from "./components/AudioPlayer";
import { ThemeProvider } from '@mui/material/styles';
import theme from "./components/FrecallTheme";
import useWakeLock from "./components/wakeLock";
import Typography from '@mui/material/Typography';


function App() {
  useWakeLock(); // Activate the wake lock

  let urlData = {};

  window.location.search.replaceAll("?","").split("&").forEach(element => {
    const elementList = element.split("=");
    urlData[elementList[0]] = elementList[1];
  })

  // console.log(urlData);

  controls.callerId = urlData.userId || "";
  controls.roomId = urlData.roomId || ""; //sets the frecall Room ID.

  if(["id", "null"].includes(controls.callerId))controls.callerId = null;
  

  // console.log(controls.callerId, ", ", controls.roomId);


  useEffect(() => {
    const handleTabClose = event => {
      event.preventDefault();

      // console.log('beforeunload event triggered');
      endCall();
      return (event.returnValue =
        'Are you sure you want to exit?');
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  return ( 
    <div className="App">
      <ThemeProvider theme={theme}>
        <div hidden><AudioPlayer/></div>
        <Loading/>
        <CustomizedSnackbars/>
        <Callpage/>
        {/* <Typography
            component="span"
            sx={{
              fontSize: '0.6rem',
              color: 'gray',
              marginLeft: '4px',
              position: 'relative',
              bottom: '-2px', // Adjusts the placement relative to the main text
            }}
          >
            v - 1.20
          </Typography> */}
      </ThemeProvider>
    </div>
  );
}

export default App;