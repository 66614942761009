import { useEffect, useRef } from 'react';

const useWakeLock = () => {
  const wakeLockRef = useRef(null);

  const requestWakeLock = async () => {
    try {
      if ('wakeLock' in navigator) {
        wakeLockRef.current = await navigator.wakeLock.request('screen');
        console.log('Wake Lock is active');
        wakeLockRef.current.addEventListener('release', () => {
          console.log('Wake Lock released');
        });
      } else {
        console.log('Wake Lock API is not supported in this browser.');
      }
    } catch (err) {
      console.error(`${err.name}, ${err.message}`);
    }
  };

  const releaseWakeLock = async () => {
    if (wakeLockRef.current) {
      await wakeLockRef.current.release();
      wakeLockRef.current = null;
      console.log('Wake Lock released manually');
    }
  };

  useEffect(() => {
    requestWakeLock();

    // Cleanup wake lock on component unmount
    return () => {
      releaseWakeLock();
    };
  }, []);
};

export default useWakeLock;
