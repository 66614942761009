import React, { useState } from 'react'
import controls from './controls';
import Backdrop from '@mui/material/Backdrop';
import {CircularProgress} from '@mui/material';

const Loading = () => {
    const [[showLoading, loadingText], setShowLoading] = useState([true, ""]);
    // showLoading && controls.setView !== null && controls.setView("");
    controls.setLoading = setShowLoading;
    // console.log(loadingText);

  return (
    <div>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={showLoading}
      onClick={()=>{setShowLoading([false, ""])}}>
      <CircularProgress color="inherit" />
      <p>{loadingText}</p>
    </Backdrop>
  </div>
  )
}

export default Loading

// export {setLoading};
