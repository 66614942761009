import { initializeApp } from "firebase/app";
import { getFirestore, collection, onSnapshot, doc } from 'firebase/firestore'
import { getFunctions, httpsCallable } from "firebase/functions";
import controls from "./controls"
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDcREtwTpF5RUE7-3Top67i5dXY6ltB-eQ",
    authDomain: "frecall-a0b07.firebaseapp.com",
    projectId: "frecall-a0b07",
    storageBucket: "frecall-a0b07.appspot.com",
    messagingSenderId: "446467769626",
    appId: "1:446467769626:web:9836e247e29e8cf83c58a3",
    measurementId: "G-VR8RSGYYF5"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);


async function getUsersOnCall(handleUserChange) {

    const referenceId = controls.roomId;

    const usersOnCallCollection = collection(db, "usersOnCall");
    const usersOnCallReference = doc(usersOnCallCollection, `${referenceId}`);
    // const usersOnCallSnap = await firestore.getDoc(usersOnCallReference);

    controls.unsub = onSnapshot(usersOnCallReference, function(doc) {
        // console.log("CHANGE");
        handleUserChange(doc.data());
    })

   
    // console.log(usersOnCallSnap.data().onCall)
  }

async function getRoomDetails(setRoomDetails) {
    const functions = getFunctions();

    const getRoomDetails = httpsCallable(functions, 'getRoomDetails');
    try {
      await getRoomDetails({"roomId": controls.roomId}).then((result)=>{
        const data = result.data;
      controls.appId = data.callAppId;
      controls.queuedAudio = data.queuedAudio;
      controls.welcomeAudio = data.welcomeAudio;
      controls.readyAudio = data.readyAudio;
      controls.youtubeVideoId = data.youtubeVideoId;
      if(data.online){

        controls.setAudio(()=>data.welcomeAudio);
        // document.getElementById("myPlayer").play();
        // console.log("AUDIOOO PLAY");
      }
      setRoomDetails(data);
      return data;
    })
    } catch(err){
      console.log("EEEEEERRR",err)
      return null;
    }
}

async function setAgoraToken(){
  const functions = getFunctions();

  const generateAgoraToken = httpsCallable(functions, 'generateAgoraToken');
  try{
    await generateAgoraToken({agent: true, channelName: controls.channelName}).then((result)=>{
    // console.log("RRRRRRR", result.data);
    controls.token = result.data;
  })
  } catch(err){
    console.log("EEEEEERRR",err)
  }

  }

export default db;
export {getUsersOnCall, setAgoraToken, getRoomDetails, auth};
